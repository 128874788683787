import { Form, useRouteLoaderData } from '@remix-run/react'
import { trackEvent } from 'fathom-client'
import { useState } from 'react'
import type { loader as RootLoader } from '~/root'
import { Loading } from './Loading'
import { UseBuyOnLoungePair } from './Mark'

export function ToggleAffiliateOffers() {
  const root = useRouteLoaderData<typeof RootLoader>('root')
  if (root?.flagsHideAffiliateOffers) return null
  if (!root?.canToggleAffiliateOffers) return null
  const [isLoading, setIsLoading] = useState(false)

  if (root?.hideAffiliateOffers) {
    return (
      <div className="flex justify-center items-center gap-1 mb-6 text-stone-500 text-center">
        <Form
          method="POST"
          action="/api/sessions"
          onSubmit={() => {
            setIsLoading(true)
          }}
          reloadDocument
        >
          <p className="inline-block my-0 bg-stone-50 px-2">
            Only showing LoungePair offers →
          </p>
          <button
            type="submit"
            name="intent"
            value="show"
            className="text-sm ml-1 font-bold"
            onClick={() => trackEvent('Show All Offers Button Clicked')}
          >
            Show all offers
          </button>
        </Form>
        <Loading isLoading={isLoading} invert={false} />
      </div>
    )
  }
  return (
    <div className="sm:flex justify-center items-center gap-1 text-stone-500 mb-6 text-center">
      <p className="my-0">
        <UseBuyOnLoungePair className="inline-block h-6 mr-2 text-stone-600" />
        Lounges you can book today with LoungePair
        <span className="hidden sm:inline-block mx-1">→</span>
      </p>
      <Form
        method="POST"
        className="text-sm"
        action="/api/sessions"
        onSubmit={() => {
          setIsLoading(true)
        }}
        reloadDocument
      >
        <button
          type="submit"
          name="intent"
          value="hide"
          className="font-bold bg-blue-50 text-blue-800 px-2"
          onClick={() => trackEvent('Hide Affiliate Offers Button Clicked')}
        >
          Show these only
        </button>
      </Form>
      <Loading isLoading={isLoading} invert={false} />
    </div>
  )
}
